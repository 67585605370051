import AttackListEntity from '@/entities/AttackListEntity'
import symbols from '@/symbols'
import { IAttackListEntityFactory, IAttackListEntity, AttackListEntityFactoryInput, IErrorsStore } from '@/types'
import { inject, injectable } from 'inversify'

@injectable()
export default class AttackListEntityFactory implements IAttackListEntityFactory {
  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: AttackListEntityFactoryInput): IAttackListEntity {
    const attackListEntity = new AttackListEntity(input.base)

    attackListEntity.errorsStore = this.errorsStore

    return attackListEntity
  }
}
