import { IErrorsStore, IUserBase, IUserMember, IUserMemberBase, UserMemberRole } from '@/types'
import { IUpdateUserMemberUseCase, UpdateUserMemberUseCaseOutput } from '@/types/useCases/userMembers'
import { action, observable } from 'mobx'

export default class UserMember implements IUserMember {
  @observable id: string

  @observable member: IUserBase

  @observable role: UserMemberRole

  errorsStore: IErrorsStore

  updateUserMemberUseCase: IUpdateUserMemberUseCase

  @action
  _mapBase(base: IUserMemberBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: IUserMemberBase): void {
    this._mapBase(base)
  }

  constructor(base: IUserMemberBase) {
    this._mapBase(base)
  }

  async save(role: UserMemberRole, targetUsername: string): Promise<UpdateUserMemberUseCaseOutput> {
    const output = await this.updateUserMemberUseCase.handle({
      id: this.id,
      role,
      targetUsername,
    })

    if (output.isSuccessful) {
      this.update(output.data.userMember)
    }

    return output
  }
}
