import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchDashboardUseCaseInput,
  FetchDashboardUseCaseOutput,
  IAppErrorFactory,
  IDashboardBase,
  IFetchDashboardUseCase,
  IUseCaseOutputFactory,
  IViewerService,
} from '@/types'

@injectable()
export default class FetchDashboardInteractor implements IFetchDashboardUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    dashboard: IDashboardBase
  }>

  async handle(input: FetchDashboardUseCaseInput): Promise<FetchDashboardUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        dashboard: null,
      },
    })

    try {
      const response = await this.viewerService.fetchDashboard(input)

      output.data.dashboard = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
