import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  AddCorporateInvestorListsUseCaseIntput,
  AddCorporateInvestorListsUseCaseOutpt,
  IAddCorporateInvestorListsUseCase,
  IAddedListBase,
  IAppErrorFactory,
  ICorporateInvestorsService,
  IUseCaseOutputFactory,
} from '@/types'

@injectable()
export default class AddCorporateInvestorListsInteractor implements IAddCorporateInvestorListsUseCase {
  @inject(symbols.ICorporateInvestorsService) private corporateInvestorService: ICorporateInvestorsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    addedList: IAddedListBase
  }>

  async handle(input: AddCorporateInvestorListsUseCaseIntput): Promise<AddCorporateInvestorListsUseCaseOutpt> {
    const output = this.outputFactory.create({
      defaultValue: {
        addedList: null,
      },
    })

    try {
      const response = await this.corporateInvestorService.addCorporateInvestorLists(input)
      output.data.addedList = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
