import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import { AngelFactoryInput, IAngel, IAngelFactory, IErrorsStore } from '@/types'
import Angel from '@/entities/Angel'

@injectable()
export default class AngelFactory implements IAngelFactory {
  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: AngelFactoryInput): IAngel {
    const angel = new Angel(input.base)

    angel.errorsStore = this.errorsStore

    return angel
  }
}
