import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchAngelsUseCaseInput,
  FetchAngelsUseCaseOutput,
  IAngel,
  IAngelFactory,
  IAngelsService,
  IAppErrorFactory,
  IFetchAngelsUseCase,
  IUseCaseOutputFactory,
} from '@/types'

@injectable()
export default class FetchAngelsInteractor implements IFetchAngelsUseCase {
  @inject(symbols.IAngelsService) private angelsService: IAngelsService

  @inject(symbols.IAngelFactory) private angelFactory: IAngelFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    angels: IAngel[]
    totalCount: number
    totalPages: number
    currentPage: number
    hasNextPage: boolean
  }>

  async handle(input: FetchAngelsUseCaseInput): Promise<FetchAngelsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        angels: [],
        totalCount: null,
        totalPages: null,
        currentPage: null,
        hasNextPage: false,
      },
    })

    try {
      const response = await this.angelsService.fetchAngels(input)
      const { angels, totalCount, totalPages, currentPage, hasNextPage } = response
      const angelEntities: IAngel[] = angels.map((base) => {
        return this.angelFactory.create({ base })
      })

      output.data.angels = angelEntities
      output.data.totalCount = totalCount
      output.data.totalPages = totalPages
      output.data.currentPage = currentPage
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
