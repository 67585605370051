import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  ICommentBase,
  ICommentsStore,
  IFetchCommentsUseCase,
  FetchCommentsStoreInput,
  FetchCommentsUseCaseOutput,
} from '@/types'

const remoteDevConfig: RemoteDevConfig = {
  name: 'CorporateInvestorsStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class CommentsStore implements ICommentsStore {
  @observable comments: ICommentBase[] = []

  @observable searchResults: ICommentBase[] = []

  @observable searchWord = ''

  @observable totalCount: number

  @observable totalPages: number

  @observable currentPage: number

  @observable hasNextPage = true

  constructor(@inject(symbols.IFetchCommentsUseCase) private fetchCommentsUseCase: IFetchCommentsUseCase) {
    //
  }

  @action
  _updateComments(comments: ICommentBase[]): void {
    this.comments = comments
  }

  @action
  _updateTotalCount(totalCount: number): void {
    this.totalCount = totalCount
  }

  @action
  _updateTotalPages(totalPages: number): void {
    this.totalPages = totalPages
  }

  @action
  _updateCurrentPage(currentPage: number): void {
    this.currentPage = currentPage
  }

  @action
  updateHasNextPage(hasNextPage: boolean): void {
    this.hasNextPage = hasNextPage
  }

  async fetchComments(input: FetchCommentsStoreInput): Promise<FetchCommentsUseCaseOutput> {
    const output = await this.fetchCommentsUseCase.handle(input)

    if (output.isSuccessful) {
      this._updateComments(output.data.comments)

      this._updateTotalCount(output.data.totalCount)
      this._updateTotalPages(output.data.totalPages)
      this._updateCurrentPage(output.data.currentPage)
      this.updateHasNextPage(output.data.hasNextPage)
    } else {
      this._updateComments([])
    }

    return output
  }
}
