import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchAngelUseCaseInput,
  FetchAngelUseCaseOutput,
  IAngel,
  IAngelFactory,
  IAngelsService,
  IAppErrorFactory,
  IFetchAngelUseCase,
  IUseCaseOutputFactory,
} from '@/types'

@injectable()
export default class FetchAngelInteractor implements IFetchAngelUseCase {
  @inject(symbols.IAngelsService) private angelsService: IAngelsService

  @inject(symbols.IAngelFactory) private angelFactory: IAngelFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    angel: IAngel
  }>

  async handle(input: FetchAngelUseCaseInput): Promise<FetchAngelUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        angel: null,
      },
    })

    try {
      const base = await this.angelsService.fetchAngel(input)
      output.data.angel = this.angelFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
