import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  AddUserMemberUseCaseInput,
  AddUserMemberUseCaseOutput,
  IAddUserMemberUseCase,
} from '@/types/useCases/userMembers'
import { IUserMembersService } from '@/types/services/userMembers'
import { IAppErrorFactory, IUseCaseOutputFactory, IUserMember, IUserMemberFactory } from '@/types'

@injectable()
export default class AddUserMemberInteractor implements IAddUserMemberUseCase {
  @inject(symbols.IUserMembersService) private userMembersService: IUserMembersService

  @inject(symbols.IUserMemberFactory) private userMemberFactory: IUserMemberFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    userMember: IUserMember
  }>

  async handle(input: AddUserMemberUseCaseInput): Promise<AddUserMemberUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        userMember: null,
      },
    })

    try {
      const response = await this.userMembersService.addUserMember(input)
      output.data.userMember = this.userMemberFactory.create({ base: response })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
