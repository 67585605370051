import { inject, injectable } from 'inversify'
import {
  AddMemberInvitationUseCaseInput,
  AddMemberInvitationUseCaseOutput,
  IAddMemberInvitationUseCase,
  IAppErrorFactory,
  IInvitationSentBase,
  IInvitationsService,
  IUseCaseOutputFactory,
} from '@/types'
import symbols from '../../symbols'

@injectable()
export default class AddMemberInvitationInteractor implements IAddMemberInvitationUseCase {
  @inject(symbols.IInvitationsService) private invitationsService: IInvitationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    invitationSent: IInvitationSentBase
  }>

  async handle(input: AddMemberInvitationUseCaseInput): Promise<AddMemberInvitationUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        invitationSent: null,
      },
    })

    try {
      const response = await this.invitationsService.addMemberInvitation(input)
      output.data.invitationSent = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
