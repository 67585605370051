import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  ICorporateInvestorBase,
  ICorporateInvestorsService,
  IToggleCorporateInvestorListUseCase,
  IUseCaseOutputFactory,
  ToggleCorporateInvestorListUseCaseInput,
  ToggleCorporateInvestorListUseCaseOutput,
} from '@/types'

@injectable()
export default class ToggleCorporateInvestorListInteractor implements IToggleCorporateInvestorListUseCase {
  @inject(symbols.ICorporateInvestorsService) private corporateInvestorService: ICorporateInvestorsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    corporateInvestor: ICorporateInvestorBase
  }>

  async handle(input: ToggleCorporateInvestorListUseCaseInput): Promise<ToggleCorporateInvestorListUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        corporateInvestor: null,
      },
    })

    try {
      const response = await this.corporateInvestorService.toggleCorporateInvestorList(input)
      output.data.corporateInvestor = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
