import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IRemoveUserMemberUseCase,
  RemoveUserMemberUseCaseInput,
  RemoveUserMemberUseCaseOutput,
} from '@/types/useCases/userMembers'
import { IAppErrorFactory, IUseCaseOutputFactory, IUserMemberBase } from '@/types'
import { IUserMembersService } from '@/types/services/userMembers'

@injectable()
export default class RemoveUserMemberInteractor implements IRemoveUserMemberUseCase {
  @inject(symbols.IUserMembersService) private userMembersService: IUserMembersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    userMember: IUserMemberBase
  }>

  async handle(input: RemoveUserMemberUseCaseInput): Promise<RemoveUserMemberUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        userMember: null,
      },
    })

    try {
      output.data.userMember = await this.userMembersService.removeUserMember(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
