import { NextPage } from 'next'
import { AppProps } from 'next/app'
import { observer, Provider } from 'mobx-react'
import { useEffect } from 'react'
import { NextRouter, useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { ThemeProvider } from 'next-themes'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Language, OnInitializedHandler, OnInitializedHandlerInput } from '@/types'
import * as gtag from '@/lib/gtag'
import stores from '@/stores'
import '@styles/globals.scss'

const MyApp: NextPage<AppProps> = observer(({ Component, pageProps }) => {
  const router = useRouter()
  const { viewer } = stores
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)

  useEffect(() => {
    void initialize()
  }, [])

  const initialize = async () => {
    await viewer.initialize(router.locale as Language, onInitialized)
  }
  const onInitialized: OnInitializedHandler = async (input): Promise<void> => {
    setUser(input)
    await redirectIfNeeded(input, router)
  }

  // client側でのページ遷移時にpageviewが発火される
  useEffect(() => {
    const handleRouteChange = (path) => {
      gtag.pageview(path)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // TODO: Stripe の locale 変更
  // ルートだしデフォルトのコードなのでここだけ許容
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Elements stripe={stripePromise} options={{ locale: 'ja' }}>
      {/* V2 のスタイル以外は light テーマなので基本 light テーマ固定。 */}
      {/* プラットフォーム側で V2 のデザインシステムの提供始めるまでは Props の forcedTheme で light 指定する。 */}
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
      <ThemeProvider defaultTheme='light' forcedTheme={pageProps?.forcedTheme || null}>
        <Provider stores={stores}>
          <Component {...pageProps} />
        </Provider>
      </ThemeProvider>
    </Elements>
  )
  /* eslint-enable react/jsx-props-no-spreading */
})

const setUser = (input: OnInitializedHandlerInput): void => {
  if (input.isSignedIn) {
    // ログインユーザーの場合
    gtag.setUserId(input.username)
    gtag.setUserProperties({
      is_signed_in: input.isSignedIn.toString(),
    })
  } else {
    // 未ログインユーザーは UserId を設定せず、UserProperties のみ設定する
    gtag.setUserProperties({
      is_signed_in: input.isSignedIn.toString(),
    })
  }
}

/**
 * リダイレクト処理
 *
 * @param input
 * @param router
 */
const redirectIfNeeded = async (input: OnInitializedHandlerInput, router: NextRouter): Promise<void> => {
  const pathName = router.pathname
  const { isSignedIn } = input
  // ログインしてないのにログインが必要なルートにいる場合はリダイレクト
  const isInCredentialsRequiredPaths = getWhetherViewerIsInPaths(credentialsRequiredPaths, pathName)
  if (!isSignedIn && isInCredentialsRequiredPaths) {
    await router.push('/')
  }

  // ログイン済みなのにログインページやサインアップページなどにいる場合はリダイレクト
  const isInSessionPaths = getWhetherViewerIsInPaths(noCredentialsPaths, pathName)
  if (isSignedIn && isInSessionPaths) {
    await router.push('/')
  }
}

const noCredentialsPaths = ['/signin', '/signup', '/password-recovery']
const credentialsRequiredPaths = ['/account', '/companies/new', '/messages'] // '/account' で '/companies/companySlug/account/profile' とかも対象になる
const getWhetherViewerIsInPaths = (targetPaths: string[], pathName: string) => {
  const paths = targetPaths.filter((path) => pathName.indexOf(path) !== -1)
  if (paths.length > 0) {
    return true
  }

  return false
}

export default appWithTranslation(MyApp)
