import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IUseCaseOutputFactory,
  IFetchCommentsUseCase,
  ICommentsService,
  FetchCommentsUseCaseInput,
  FetchCommentsUseCaseOutput,
  ICommentBase,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class FetchCommentsInteractor implements IFetchCommentsUseCase {
  @inject(symbols.ICommentsService) private commentService: ICommentsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    comments: ICommentBase[]
    totalCount: number
    totalPages: number
    currentPage: number
    hasNextPage: boolean
  }>

  async handle(input: FetchCommentsUseCaseInput): Promise<FetchCommentsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        comments: [],
        totalCount: null,
        totalPages: null,
        currentPage: null,
        hasNextPage: false,
      },
    })

    try {
      const response = await this.commentService.fetchComments(input)
      const { comments, totalCount, totalPages, currentPage, hasNextPage } = response

      output.data.comments = comments
      output.data.totalCount = totalCount
      output.data.totalPages = totalPages
      output.data.currentPage = currentPage
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
