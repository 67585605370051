import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IFetchLocationsUseCase,
  ILocationsService,
  FetchLocationsUseCaseOutput,
  IUseCaseOutputFactory,
  ILocationBase,
  FetchLocationsUseCaseInput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class FetchLoationsInteractor implements IFetchLocationsUseCase {
  @inject(symbols.ILocationsService) private locationService: ILocationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    locations: ILocationBase[]
  }>

  async handle(input: FetchLocationsUseCaseInput): Promise<FetchLocationsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        locations: [],
      },
    })
    try {
      const base = await this.locationService.fetchLocations(input)
      output.data.locations = base
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
