import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  ISearchUserByEmailUseCase,
  IUseCaseOutputFactory,
  IUserBase,
  IUsersService,
  SearchUserByEmailUseCaseInput,
  SearchUserByEmailUseCaseOutput,
} from '@/types'

@injectable()
export default class SearchUserByEmailInteractor implements ISearchUserByEmailUseCase {
  @inject(symbols.IUsersService) private userService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    user: IUserBase
  }>

  async handle(input: SearchUserByEmailUseCaseInput): Promise<SearchUserByEmailUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        user: null,
      },
    })

    try {
      const response = await this.userService.searchUserByEmail(input)
      output.data.user = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
