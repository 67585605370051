import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAddCommentToCorporateInvestorUseCase,
  ICommentsService,
  IAppErrorFactory,
  ICommentBase,
  IUseCaseOutputFactory,
  AddCommentToCorporateInvestorUseCaseInput,
  AddCommentToCorporateInvestorUseCaseOutput,
} from '@/types'

@injectable()
export default class AddCommentToCorporateInvestorInteractor implements IAddCommentToCorporateInvestorUseCase {
  @inject(symbols.ICommentsService) private commentsService: ICommentsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    comment: ICommentBase
  }>

  async handle(input: AddCommentToCorporateInvestorUseCaseInput): Promise<AddCommentToCorporateInvestorUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        comment: null,
      },
    })

    try {
      output.data.comment = await this.commentsService.addCommentToCorporateInvestor(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
