import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  ISearchUserByEmailUseCase,
  IUsersStore,
  SearchUserByEmailStoreInput,
  SearchUserByEmailUseCaseOutput,
} from '@/types'

const remoteDevConfig: RemoteDevConfig = {
  name: 'UsersStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class UsersStore implements IUsersStore {
  constructor(@inject(symbols.ISearchUserByEmailUseCase) private searchUserByEmailUseCase: ISearchUserByEmailUseCase) {
    //
  }

  async searchUserByEmail(input: SearchUserByEmailStoreInput): Promise<SearchUserByEmailUseCaseOutput> {
    const output = await this.searchUserByEmailUseCase.handle(input)

    return output
  }
}
