import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import { CorporateInvestorFactoryInput, ICorporateInvestor, ICorporateInvestorFactory, IErrorsStore } from '@/types'
import CorporateInvestor from '@/entities/CorporateInvestor'

@injectable()
export default class CorporateInvestorFactory implements ICorporateInvestorFactory {
  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: CorporateInvestorFactoryInput): ICorporateInvestor {
    const corporateInvestor = new CorporateInvestor(input.base)

    corporateInvestor.errorsStore = this.errorsStore

    return corporateInvestor
  }
}
