import { injectable } from 'inversify'
import { IUserProfileBase, IUserProfileFactory, UserProfileFactoryInput } from '@/types'
import UserProfile from '@/entities/UserProfile'

@injectable()
export default class UserProfileFactory implements IUserProfileFactory {
  public create(input: UserProfileFactoryInput): IUserProfileBase {
    return new UserProfile(input.base)
  }
}
