import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  FetchMarketsUseCaseOutput,
  IFetchMarketsUseCase,
  IMarketsService,
  IMarketBase,
  IUseCaseOutputFactory,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchMarketsInteractor implements IFetchMarketsUseCase {
  @inject(symbols.IMarketsService) private marketService: IMarketsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    markets: IMarketBase[]
  }>

  async handle(): Promise<FetchMarketsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        markets: [],
      },
    })

    try {
      const base = await this.marketService.fetchMarkets()
      output.data.markets = base
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
