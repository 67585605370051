import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreatePaymentLinksUseCaseInput,
  CreatePaymentLinksUseCaseOutput,
  IAppErrorFactory,
  ICreatePaymentLinksUseCase,
  ISubscriptionsService,
} from '../../types'

@injectable()
export default class CreatePaymentLinksInteractor implements ICreatePaymentLinksUseCase {
  @inject(symbols.ISubscriptionsService) private subscriptionsService: ISubscriptionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreatePaymentLinksUseCaseInput): Promise<CreatePaymentLinksUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const paymentLinksBase = await this.subscriptionsService.createPaymentLinks(input)
      output.paymentLink = paymentLinksBase
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '支払いリンクの発行に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): CreatePaymentLinksUseCaseOutput {
    return {
      paymentLink: null,
      error: null,
    }
  }
}
