import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IRemoveAttackListsUseCase,
  IRemovedListBase,
  IUseCaseOutputFactory,
  IViewerService,
  RemoveAttackListsUseCaseInput,
  RemoveAttackListsUseCaseOutput,
} from '@/types'

@injectable()
export default class RemoveAttackListsInteractor implements IRemoveAttackListsUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    removedList: IRemovedListBase
  }>

  async handle(input: RemoveAttackListsUseCaseInput): Promise<RemoveAttackListsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        removedList: null,
      },
    })

    try {
      const response = await this.viewerService.removeAttackLists(input)
      output.data.removedList = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
