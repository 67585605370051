import { observable } from 'mobx'
import { IUserProfileBase, UserProfileKindBase } from '@/types'

export default class UserProfile implements IUserProfileBase {
  @observable avatar: string

  @observable kind: UserProfileKindBase

  constructor(base: IUserProfileBase) {
    this.avatar = base.avatar
    this.kind = base.kind
  }
}
