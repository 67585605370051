import { action, computed, observable } from 'mobx'
import { inject, injectable } from 'inversify'
import { IAppCredentials, IAppCredentialsBase, IStorageService } from '@/types'
import symbols from '@/symbols'
import { isBrowser } from '@/utils'
import UnauthenticatedError from '@/errors/UnauthenticatedError'
import UninitializedError from '@/errors/UninitializedError'

@injectable()
export default class AppCredentials implements IAppCredentials {
  @inject(symbols.IStorageService) private storageService: IStorageService

  @observable accessToken: string = null

  @observable client: string = null

  @observable expiry: number = null

  @observable tokenType: string = null

  @observable uid: string = null

  static readonly STORAGE_KEY = 'credentials'

  static readonly CORPORATE_INVESTOR_KEY = 'corporateInvestorKey'

  /**
   * storage から credentials を復元
   */
  restore(): boolean {
    const credentialsString = this._restoreCurrentCredentials()
    if (!credentialsString) {
      // storage になかった場合にアーリーリターン
      return false
    }

    // メモリにも保持
    const credentials = JSON.parse(credentialsString) as IAppCredentialsBase
    this._update(credentials)

    return true
  }

  /**
   * storage の credentials に更新があるか確認して、
   * 更新があればメモリ上の credentials を同じものに更新
   */
  sync(): void {
    if (!isBrowser()) {
      return
    }

    const credentialsString = this._restoreCurrentCredentials()
    if (!credentialsString) {
      if (this.accessToken) {
        // storage になかった場合、メモリ上でも削除してアーリーリターン
        // 別タブでログアウトしているケース
        this._clear()
        throw new UnauthenticatedError('Outdated Credentials')
      }

      // ログインしてなければ（メモリ上に accessToken がなければ）特に何もせずリターン
      return
    }

    const credentials = JSON.parse(credentialsString) as IAppCredentialsBase
    if (credentials.accessToken === this.accessToken) {
      // 更新があるか確認して、なければアーリーリターン
      return
    }

    if (!this.accessToken) {
      // storage に credentials あるのにメモリ上にはない場合
      // 別タブでログインしたケース
      // fetchMe とか初期化し直す必要があるので強制リロードさせる
      throw new UninitializedError('Uninitialized')
    }

    // 更新がある場合はメモリ上の credentials を最新 (storage) のものに更新
    this._update(credentials)
  }

  /**
   * 新しい Credentials をメモリとストレージに保存
   *
   * @param base
   */
  update(base: IAppCredentialsBase): void {
    this._update(base)
    this._saveCurrentCredentials()
  }

  /**
   * 現在の Credentials をメモリとストレージから削除
   */
  clear(): void {
    this._clear()
    this._clearCurrentCredentials()
  }

  /**
   * 新しい AccessToken をメモリとストレージに保存
   *
   * @param accessToken
   */
  updateAccessToken(accessToken: string): void {
    this._updateAccessToken(accessToken)
    this._saveCurrentCredentials()
  }

  /**
   * storage から復元
   */
  _restoreCurrentCredentials(): string {
    return this.storageService.getItem(AppCredentials.STORAGE_KEY)
  }

  /**
   * storage に保存
   */
  _saveCurrentCredentials(): void {
    this.storageService.setItem(AppCredentials.STORAGE_KEY, JSON.stringify(this.base))
  }

  /**
   * storage をクリア
   */
  _clearCurrentCredentials(): void {
    this.storageService.removeItem(AppCredentials.STORAGE_KEY)
  }

  @action
  _update(base: IAppCredentialsBase): void {
    this.accessToken = base.accessToken
    this.client = base.client
    this.expiry = base.expiry
    this.tokenType = base.tokenType
    this.uid = base.uid
  }

  @action
  _clear(): void {
    this.accessToken = null
    this.client = null
    this.expiry = null
    this.tokenType = null
    this.uid = null
  }

  @action
  _updateAccessToken(accessToken: string): void {
    this.accessToken = accessToken
  }

  @computed
  get isSignedIn(): boolean {
    return this.accessToken !== null
  }

  @computed
  get base(): IAppCredentialsBase {
    return {
      accessToken: this.accessToken,
      client: this.client,
      expiry: this.expiry,
      tokenType: this.tokenType,
      uid: this.uid,
    }
  }

  /**
   * storage を確認して最新の credentials を返す
   */
  getLatestCredentials(): IAppCredentialsBase {
    this.sync()
    return this.base
  }

  /**
   * ゲストユーザーの投資家閲覧用
   *
   * @param slug
   */
  storeCorporateInvestorSlug(slug: string): void {
    // 現在のスラッグが既に保存されているかを確認
    const storedData = this.storageService.getItem(AppCredentials.CORPORATE_INVESTOR_KEY)
    const obj = storedData ? (JSON.parse(storedData) as { corporateInvestorSlug?: string }) : {}

    if (!storedData || obj.corporateInvestorSlug === '') {
      this.storageService.setItem(
        AppCredentials.CORPORATE_INVESTOR_KEY,
        JSON.stringify({ corporateInvestorSlug: slug })
      )
    }
  }

  hasViewedCorporateInvestorBefore(slug: string): boolean {
    let storedData = this.storageService.getItem(AppCredentials.CORPORATE_INVESTOR_KEY)

    if (!storedData) {
      this.storeCorporateInvestorSlug(slug)
      storedData = this.storageService.getItem(AppCredentials.CORPORATE_INVESTOR_KEY)
    }

    const obj = JSON.parse(storedData) as { corporateInvestorSlug?: string }
    return obj.corporateInvestorSlug === slug
  }
}
