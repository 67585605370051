import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import { IErrorsStore, IUserMember, IUserMemberFactory, UserMemberFactoryInput } from '@/types'
import UserMember from '@/entities/UserMember'
import { IUpdateUserMemberUseCase } from '@/types/useCases/userMembers'

@injectable()
export default class UserMemberFactory implements IUserMemberFactory {
  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  @inject(symbols.IUpdateUserMemberUseCase) private updateUserMemberUseCase: IUpdateUserMemberUseCase

  public create(input: UserMemberFactoryInput): IUserMember {
    const userMember = new UserMember(input.base)

    userMember.errorsStore = this.errorsStore
    userMember.updateUserMemberUseCase = this.updateUserMemberUseCase

    return userMember
  }
}
