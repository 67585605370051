import { useStaticRendering } from 'mobx-react'
import { container } from '@/inversify.config'
import symbols from '@/symbols'
import {
  IAngelsStore,
  ICorporateInvestorsStore,
  IErrorsStore,
  IMessagesStore,
  IStores,
  IUIStore,
  IUsersStore,
  IViewerStore,
  ICommentsStore,
} from '@/types'

// 開発時のSSRのメモリリーク対策
// cf., https://mobx-react.js.org/recipes-ssr
// eslint-disable-next-line react-hooks/rules-of-hooks
useStaticRendering(typeof window === 'undefined')

// jest setup の際 container が null で処理が実行されないので、三項演算子を追加
const stores: IStores = container
  ? {
      ui: container.get<IUIStore>(symbols.IUIStore),
      messages: container.get<IMessagesStore>(symbols.IMessagesStore),
      errors: container.get<IErrorsStore>(symbols.IErrorsStore),
      viewer: container.get<IViewerStore>(symbols.IViewerStore),
      corporateInvestors: container.get<ICorporateInvestorsStore>(symbols.ICorporateInvestorsStore),
      angels: container.get<IAngelsStore>(symbols.IAngelsStore),
      users: container.get<IUsersStore>(symbols.IUsersStore),
      comments: container.get<ICommentsStore>(symbols.ICommentsStore),
    }
  : null

export default stores
