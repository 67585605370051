import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchUserMembersUseCaseInput,
  FetchUserMembersUseCaseOutput,
  IFetchUserMembersUseCase,
} from '@/types/useCases/userMembers'
import { IUserMembersService } from '@/types/services/userMembers'
import { IAppErrorFactory, IUseCaseOutputFactory, IUserMember, IUserMemberFactory } from '@/types'

@injectable()
export default class FetchUserMembersInteractor implements IFetchUserMembersUseCase {
  @inject(symbols.IUserMembersService) private userMembersService: IUserMembersService

  @inject(symbols.IUserMemberFactory) private userMemberFactory: IUserMemberFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    userMembers: IUserMember[]
  }>

  async handle(input: FetchUserMembersUseCaseInput): Promise<FetchUserMembersUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        userMembers: [],
      },
    })

    try {
      const response = await this.userMembersService.fetchUserMembers(input)
      const userMembers: IUserMember[] = response.map((base) => {
        return this.userMemberFactory.create({ base })
      })

      output.data.userMembers = userMembers
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
