import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IInvitationBase,
  IInvitationsService,
  IUseCaseOutputFactory,
  IValidInvitationTokenUseCase,
  ValidInvitationTokenUseCaseInput,
  ValidInvitationTokenUseCaseOutput,
} from '@/types'
import symbols from '../../symbols'

@injectable()
export default class ValidInvitationTokenInteractor implements IValidInvitationTokenUseCase {
  @inject(symbols.IInvitationsService) private invitationsService: IInvitationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    invitation: IInvitationBase
  }>

  async handle(input: ValidInvitationTokenUseCaseInput): Promise<ValidInvitationTokenUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        invitation: null,
      },
    })

    try {
      const response = await this.invitationsService.validInvitationToken(input)
      output.data.invitation = response
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
