import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchAttackListsUseCaseInput,
  FetchAttackListsUseCaseOutput,
  IAttackListEntity,
  IAttackListEntityFactory,
  IAppErrorFactory,
  IFetchAttackListsUseCase,
  IUseCaseOutputFactory,
  IViewerService,
} from '@/types'

@injectable()
export default class FetchAttackListsInteractor implements IFetchAttackListsUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAttackListEntityFactory) private attackListEntityFactory: IAttackListEntityFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    attackLists: IAttackListEntity[]
    totalCount: number
    totalPages: number
    currentPage: number
    hasNextPage: boolean
  }>

  async handle(input: FetchAttackListsUseCaseInput): Promise<FetchAttackListsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        attackLists: [],
        totalCount: null,
        totalPages: null,
        currentPage: null,
        hasNextPage: false,
      },
    })

    try {
      const response = await this.viewerService.fetchAttackLists(input)
      const { attackLists, totalCount, totalPages, currentPage, hasNextPage } = response
      const attackListEntities: IAttackListEntity[] = attackLists.map((base) => {
        return this.attackListEntityFactory.create({ base })
      })

      output.data.attackLists = attackListEntities
      output.data.totalCount = totalCount
      output.data.totalPages = totalPages
      output.data.currentPage = currentPage
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
