import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchAngelStoreInput,
  FetchAngelUseCaseOutput,
  FetchAngelsStoreInput,
  FetchAngelsUseCaseOutput,
  IAngel,
  IAngelsStore,
  IFetchAngelUseCase,
  IFetchAngelsUseCase,
} from '@/types'

const remoteDevConfig: RemoteDevConfig = {
  name: 'AngelsStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class AngelsStore implements IAngelsStore {
  @observable angels: IAngel[] = []

  @observable searchResults: IAngel[] = []

  @observable searchWord = ''

  @observable angelLists: IAngel[] = []

  @observable totalCount: number

  @observable totalPages: number

  @observable currentPage: number

  @observable hasNextPage = true

  @observable numberOfAllAngels: number

  @observable fetchCounter = 0

  constructor(
    @inject(symbols.IFetchAngelsUseCase) private fetchAngelsUseCase: IFetchAngelsUseCase,
    @inject(symbols.IFetchAngelUseCase) private fetchAngelUseCase: IFetchAngelUseCase
  ) {
    //
  }

  @action
  _updateAngels(angels: IAngel[]): void {
    this.angels = angels
  }

  @action
  _addAngels(angels: IAngel[]): void {
    angels.forEach((newAngel) => {
      if (this.angels.some((a) => a.slug === newAngel.slug)) {
        return
      }

      this.angels = this.angels.concat(newAngel)
    })
  }

  @action
  _updateTotalCount(totalCount: number): void {
    this.totalCount = totalCount
  }

  @action
  _updateTotalPages(totalPages: number): void {
    this.totalPages = totalPages
  }

  @action
  _updateCurrentPage(currentPage: number): void {
    this.currentPage = currentPage
  }

  @action
  updateHasNextPage(hasNextPage: boolean): void {
    this.hasNextPage = hasNextPage
  }

  async fetchAngels(input: FetchAngelsStoreInput): Promise<FetchAngelsUseCaseOutput> {
    const output = await this.fetchAngelsUseCase.handle(input)

    if (output.isSuccessful) {
      this._updateAngels(output.data.angels)
      this._updateTotalCount(output.data.totalCount)
      this._updateTotalPages(output.data.totalPages)
      this._updateCurrentPage(output.data.currentPage)
      this.updateHasNextPage(output.data.hasNextPage)

      if (this.fetchCounter === 0) {
        this.numberOfAllAngels = output.data.totalCount
      }

      this.fetchCounter++
    } else {
      this._updateAngels([])
    }

    return output
  }

  async fetchAngel(input: FetchAngelStoreInput): Promise<FetchAngelUseCaseOutput> {
    const output = await this.fetchAngelUseCase.handle(input)

    return output
  }
}
