const symbols = {
  // ============================================================
  // Stores
  // ============================================================
  IUIStore: Symbol.for('IUIStore'),
  IErrorsStore: Symbol.for('IErrorsStore'),
  IMessagesStore: Symbol.for('IMessagesStore'),
  IViewerStore: Symbol.for('IViewerStore'),
  ICorporateInvestorsStore: Symbol.for('ICorporateInvestorsStore'),
  IAngelsStore: Symbol.for('IAngelsStore'),
  IUsersStore: Symbol.for('IUsersStore'),
  ICommentsStore: Symbol.for('ICommentsStore'),

  // ============================================================
  // Entities
  // ============================================================
  IAppCredentials: Symbol.for('IAppCredentials'),
  IPreferences: Symbol.for('IPreferences'),
  IViewer: Symbol.for('IViewer'),
  ICorporateInvestor: Symbol.for('ICorporateInvestor'),
  IAngel: Symbol.for('IAngel'),
  IUserMember: Symbol.for('IUserMember'),

  // ============================================================
  // Factories
  // ============================================================
  IAppErrorFactory: Symbol.for('IAppErrorFactory'),
  IUseCaseOutputFactory: Symbol.for('IUseCaseOutputFactory'),
  IMessageFactory: Symbol.for('IMessageFactory'),
  ICorporateInvestorFactory: Symbol.for('ICorporateInvestorFactory'),
  IAngelFactory: Symbol.for('IAngelFactory'),
  IAttackListEntityFactory: Symbol.for('IAttackListEntityFactory'),
  IUserMemberFactory: Symbol.for('IUserMemberFactory'),
  IUserProfileFactory: Symbol.for('IUserProfileFactory'),

  // ============================================================
  // Services
  // ============================================================
  IStorageService: Symbol.for('IStorageService'),
  IAuthService: Symbol.for('IAuthService'),
  IViewerService: Symbol.for('IViewerService'),
  IUsersService: Symbol.for('IUsersService'),
  IMarketsService: Symbol.for('IMarketsService'),
  IErrorTrackingService: Symbol.for('IErrorTrackingService'),
  ICorporateInvestorsService: Symbol.for('ICorporateInvestorsService'),
  IRoundsService: Symbol.for('IRoundsService'),
  IAngelsService: Symbol.for('IAngelsService'),
  IUserMembersService: Symbol.for('IUserMembersService'),
  ICommentsService: Symbol.for('ICommentsService'),
  IInvitationsService: Symbol.for('IInvitationsService'),
  ISubscriptionsService: Symbol.for('ISubscriptionsService'),
  ILocationsService: Symbol.for('ILocationsService'),

  // ============================================================
  // UseCases
  // ============================================================
  // Viewer
  ISignInUseCase: Symbol.for('ISignInUseCase'),
  ISignUpUseCase: Symbol.for('ISignUpUseCase'),
  ISignOutUseCase: Symbol.for('ISignOutUseCase'),
  ISendResetPasswordRequestUseCase: Symbol.for('ISendResetPasswordRequestUseCase'),
  IUpdatePasswordUseCase: Symbol.for('IUpdatePasswordUseCase'),
  IUpdatePasswordWithTokenUseCase: Symbol.for('IUpdatePasswordWithTokenUseCase'),
  IUpdateEmailUseCase: Symbol.for('IUpdateEmailUseCase'),
  IUpdateMeUseCase: Symbol.for('IUpdateMeUseCase'),
  IInitializeUseCase: Symbol.for('IInitializeUseCase'),
  IFetchMeUseCase: Symbol.for('IFetchMeUseCase'),
  IChangeLanguageUseCase: Symbol.for('IChangeLanguageUseCase'),
  IFetchAttackListsUseCase: Symbol.for('IFetchAttackListsUseCase'),
  IFetchDashboardUseCase: Symbol.for('IFetchDashboardUseCase'),
  IRemoveAttackListsUseCase: Symbol.for('IRemoveAttackListsUseCase'),
  // corporateInvestors
  IFetchCorporateInvestorsUseCase: Symbol.for('IFetchCorporateInvestorsUseCase'),
  IFetchCorporateInvestorUseCase: Symbol.for('IFetchCorporateInvestorUseCase'),
  IToggleCorporateInvestorListUseCase: Symbol.for('IToggleCorporateInvestorListUseCase'),
  IUpdateCorporateInvestorListUseCase: Symbol.for('IUpdateCorporateInvestorListUseCase'),
  IAddCorporateInvestorListsUseCase: Symbol.for('IAddCorporateInvestorListsUseCase'),
  // angels
  IFetchAngelsUseCase: Symbol.for('IFetchAngelsUseCase'),
  IFetchAngelUseCase: Symbol.for('IFetchAngelUseCase'),
  IToggleAngelListUseCase: Symbol.for('IToggleAngelListUseCase'),
  IUpdateAngelListUseCase: Symbol.for('IUpdateAngelListUseCase'),
  IAddAngelListsUseCase: Symbol.for('IAddAngelListsUseCase'),
  // markets
  IFetchMarketsUseCase: Symbol.for('IFetchMarketsUseCase'),
  // rounds
  IFetchRoundsUseCase: Symbol.for('IFetchRoundsUseCase'),
  // locations
  IFetchLocationsUseCase: Symbol.for('IFetchLocationsUseCase'),
  // userMembers
  IFetchUserMembersUseCase: Symbol.for('IFetchUserMembersUseCase'),
  IAddUserMemberUseCase: Symbol.for('IAddUserMemberUseCase'),
  IUpdateUserMemberUseCase: Symbol.for('IUpdateUserMemberUseCase'),
  IRemoveUserMemberUseCase: Symbol.for('IRemoveUserMemberUseCase'),
  // users
  ISearchUserByEmailUseCase: Symbol.for('ISearchUserByEmailUseCase'),
  // comments
  IFetchCommentsUseCase: Symbol.for('IFetchCommentsUseCase'),
  IAddCommentToCorporateInvestorUseCase: Symbol.for('IAddCommentToCorporateInvestorUseCase'),
  IUpdateCommentToCorporateInvestorUseCase: Symbol.for('IUpdateCommentToCorporateInvestorUseCase'),
  IRemoveCommentToCorporateInvestorUseCase: Symbol.for('IRemoveCommentToCorporateInvestorUseCase'),
  IAddCommentToAngelUseCase: Symbol.for('IAddCommentToAngelUseCase'),
  IUpdateCommentToAngelUseCase: Symbol.for('IUpdateCommentToAngelUseCase'),
  IRemoveCommentToAngelUseCase: Symbol.for('IRemoveCommentToAngelUseCase'),
  // invitations
  IValidInvitationTokenUseCase: Symbol.for('IValidInvitationTokenUseCase'),
  IAddMemberInvitationUseCase: Symbol.for('IAddMemberInvitationUseCase'),
  // subscriptions
  ICreateBillingPortalSessionUseCase: Symbol.for('ICreateBillingPortalSessionUseCase'),
  ICreateDbSubscriptionUseCase: Symbol.for('ICreateDbSubscriptionUseCase'),
  ICreatePaymentLinksUseCase: Symbol.for('ICreatePaymentLinksUseCase'),
}

export default symbols
