import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IUseCaseOutputFactory,
  IFetchCorporateInvestorsUseCase,
  ICorporateInvestorsService,
  FetchCorporateInvestorsUseCaseInput,
  FetchCorporateInvestorsUseCaseOutput,
  ICorporateInvestorFactory,
  ICorporateInvestor,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class FetchCorporateInvestorsInteractor implements IFetchCorporateInvestorsUseCase {
  @inject(symbols.ICorporateInvestorsService) private corporateInvestorService: ICorporateInvestorsService

  @inject(symbols.ICorporateInvestorFactory) private corporateInvestorFactory: ICorporateInvestorFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    corporateInvestors: ICorporateInvestor[]
    totalCount: number
    totalPages: number
    currentPage: number
    hasNextPage: boolean
  }>

  async handle(input: FetchCorporateInvestorsUseCaseInput): Promise<FetchCorporateInvestorsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        corporateInvestors: [],
        totalCount: null,
        totalPages: null,
        currentPage: null,
        hasNextPage: false,
      },
    })

    try {
      const response = await this.corporateInvestorService.fetchCorporateInvestors(input)
      const { corporateInvestors, totalCount, totalPages, currentPage, hasNextPage } = response
      const corporateInvestorEntities: ICorporateInvestor[] = corporateInvestors.map((base) => {
        return this.corporateInvestorFactory.create({ base })
      })

      output.data.corporateInvestors = corporateInvestorEntities
      output.data.totalCount = totalCount
      output.data.totalPages = totalPages
      output.data.currentPage = currentPage
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
