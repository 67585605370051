import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchCorporateInvestorStoreInput,
  FetchCorporateInvestorUseCaseOutput,
  FetchCorporateInvestorsStoreInput,
  FetchCorporateInvestorsUseCaseOutput,
  FetchLocationsStoreInput,
  ICommentBase,
  ICorporateInvestor,
  ICorporateInvestorsStore,
  IFetchCorporateInvestorUseCase,
  IFetchCorporateInvestorsUseCase,
  IFetchLocationsUseCase,
  IFetchMarketsUseCase,
  IFetchRoundsUseCase,
  ILocationBase,
  IMarketBase,
  IRoundBase,
} from '@/types'

const remoteDevConfig: RemoteDevConfig = {
  name: 'CorporateInvestorsStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class CorporateInvestorsStore implements ICorporateInvestorsStore {
  @observable corporateInvestors: ICorporateInvestor[] = []

  @observable searchResults: ICorporateInvestor[] = []

  @observable searchWord = ''

  @observable markets: IMarketBase[] = []

  @observable rounds: IRoundBase[] = []

  @observable locations: ILocationBase[] = []

  @observable corporateInvestorLists: ICorporateInvestor[] = []

  @observable comments: ICommentBase[] = []

  @observable totalCount: number

  @observable totalPages: number

  @observable currentPage: number

  @observable hasNextPage = true

  @observable numberOfAllCorporateInvestors: number

  @observable fetchCounter = 0

  constructor(
    @inject(symbols.IFetchCorporateInvestorsUseCase)
    private fetchCorporateInvestorsUseCase: IFetchCorporateInvestorsUseCase,
    @inject(symbols.IFetchCorporateInvestorUseCase)
    private fetchCorporateInvestorUseCase: IFetchCorporateInvestorUseCase,
    @inject(symbols.IFetchMarketsUseCase) private fetchMarketsUseCase: IFetchMarketsUseCase,
    @inject(symbols.IFetchRoundsUseCase) private fetchRoundsUseCase: IFetchRoundsUseCase,
    @inject(symbols.IFetchLocationsUseCase) private fetchLocationsUseCase: IFetchLocationsUseCase
  ) {
    //
  }

  @action
  _updatecorporateInvestors(corporateInvestors: ICorporateInvestor[]): void {
    this.corporateInvestors = corporateInvestors
  }

  @action
  _updateTotalCount(totalCount: number): void {
    this.totalCount = totalCount
  }

  @action
  _updateTotalPages(totalPages: number): void {
    this.totalPages = totalPages
  }

  @action
  _updateCurrentPage(currentPage: number): void {
    this.currentPage = currentPage
  }

  @action
  updateHasNextPage(hasNextPage: boolean): void {
    this.hasNextPage = hasNextPage
  }

  @action
  _updateMarkets(markets: IMarketBase[]): void {
    this.markets = markets
  }

  @action
  _updateRounds(markets: IRoundBase[]): void {
    this.rounds = markets
  }

  @action
  _updateLocations(locations: ILocationBase[]): void {
    this.locations = locations
  }

  async fetchCorporateInvestors(
    input: FetchCorporateInvestorsStoreInput
  ): Promise<FetchCorporateInvestorsUseCaseOutput> {
    const output = await this.fetchCorporateInvestorsUseCase.handle(input)

    if (output.isSuccessful) {
      this._updatecorporateInvestors(output.data.corporateInvestors)

      this._updateTotalCount(output.data.totalCount)
      this._updateTotalPages(output.data.totalPages)
      this._updateCurrentPage(output.data.currentPage)
      this.updateHasNextPage(output.data.hasNextPage)

      if (this.fetchCounter === 0) {
        this.numberOfAllCorporateInvestors = output.data.totalCount
      }

      this.fetchCounter++
    } else {
      this._updatecorporateInvestors([])
    }

    return output
  }

  async fetchCorporateInvestor(input: FetchCorporateInvestorStoreInput): Promise<FetchCorporateInvestorUseCaseOutput> {
    const output = await this.fetchCorporateInvestorUseCase.handle(input)

    return output
  }

  async fetchMarkets(): Promise<void> {
    const output = await this.fetchMarketsUseCase.handle()
    const originalMarkets = output.data.markets
    const itMarketIndex = originalMarkets.findIndex((market) => market.slug === 'all')
    if (itMarketIndex) {
      const itMarket = originalMarkets.splice(itMarketIndex, itMarketIndex + 1)

      originalMarkets.unshift(...itMarket)
    }

    this._updateMarkets(originalMarkets)
  }

  async fetchRounds(): Promise<void> {
    const output = await this.fetchRoundsUseCase.handle()

    this._updateRounds(output.data.rounds)
  }

  async fetchLocations(input: FetchLocationsStoreInput): Promise<void> {
    const output = await this.fetchLocationsUseCase.handle(input)

    this._updateLocations(output.data.locations)
  }
}
