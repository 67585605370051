import {
  CompanyBase,
  IAngel,
  IAngelBase,
  IErrorsStore,
  InvestInLps,
  InvestmentPurpose,
  InvestmentTargetRegion,
  LocationBase,
  MarketBase,
  RoundBase,
} from '@/types'
import { action, observable } from 'mobx'

export default class Angel implements IAngel {
  @observable about = ''

  @observable id = ''

  @observable investInLps = InvestInLps.UNKNOWN

  @observable investmentPurpose = InvestmentPurpose.UNKNOWN

  @observable investmentTargetMarkets: MarketBase[] = []

  @observable investmentTargetRegion = InvestmentTargetRegion.UNKNOWN

  @observable investmentTargetRounds: RoundBase[] = []

  @observable isListed = false

  @observable locations: LocationBase[] = []

  @observable logo = ''

  @observable name = ''

  @observable portfolioCount = 0

  @observable portfolios: CompanyBase[] = []

  @observable slug = ''

  @observable protocolSlug = ''

  @observable xUrl = ''

  @observable facebookUrl = ''

  @observable linkedinUrl = ''

  errorsStore: IErrorsStore

  @action
  _mapBase(base: IAngelBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: IAngelBase): void {
    this._mapBase(base)
  }

  constructor(base: IAngelBase) {
    this._mapBase(base)
  }
}
